import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify'
import VueRouter from 'vue-router'
import BootstrapVue from 'bootstrap-vue'
import VueCookies from 'vue-cookies'
import 'vuetify/dist/vuetify.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/tutor.css'
import VueChatScroll from 'vue-chat-scroll';
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue';
import OneSignalVue from 'onesignal-vue'
import VueCryptojs from 'vue-cryptojs'
import axios from 'axios';
import VueAxios from 'vue-axios';
import Echo from 'laravel-echo'
 
Vue.config.productionTip = false
Vue.use(Vuetify);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(VueCryptojs);
// Vue.use(VueAxios);
Vue.use(VueCookies);
Vue.use(VueChatScroll);
Vue.use(OneSignalVue);

Vue.use(TawkMessengerVue, {
  propertyId : '62151ab1a34c245641279d8c',
  widgetId : '1fsh70i4e'
});
Vue.use(VueAxios, axios);

window.Pusher = require('pusher-js');

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  forceTLS: true,
});

new Vue({
  router: router,
  vuetify: new Vuetify(),
  render: h => h(App),
  beforeMount() {
    this.$OneSignal.init({ appId: process.env.VUE_APP_ONESIGNAL_APP_ID,subdomainName:'fromyourtutor'})
  }
}).$mount('#app')
