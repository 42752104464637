<template>
  <div class="page-header">
      <div class="container">
        <div class="row">
            <!-- page caption -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                <div class="page-caption">
                    <h1 class="page-title" v-if="tutor_detail">{{tutor_head}}</h1> 
                    <h1 class="page-title" v-else>{{title}}</h1> 
                    <p style="display:none" class="text-white mb-0" v-if="!show_breadcrumb"><span class="mr-2"><i class="fas fa-map-marker-alt "></i></span>{{tutor.region.name}}, {{tutor.country.name}}</p>
                </div>
            </div>
              <!-- /.page caption -->
          </div>
      </div>
      <!-- page caption -->
      <div class="page-breadcrumb" v-if="show_breadcrumb">
          <div class="container" style="padding-top:9px;">
              <div class="row">
                  <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item"><a href="/" class="breadcrumb-link">Home</a></li>
                          <li class="breadcrumb-item" v-if="(title == 'Cart')||Object.keys(this.$route.params).includes('material')"><a href="/study_materials" class="breadcrumb-link">Study Materials</a></li>
                          <li class="breadcrumb-item" v-if="$route.fullPath.split('/').includes('pricing') && $route.path != '/pricing'"><a :href="'/pricing?id='+$route.query.tutor_id" class="breadcrumb-link">Pricing</a></li>
                          <li class="breadcrumb-item" v-if="tutor_detail"><a href="'/tutors'" class="breadcrumb-link">Tutors</a></li>
                          <li class="breadcrumb-item active text-white" aria-current="page">{{breadcrumb}}</li>
                      </ol>
                  </nav>
              </div>
          </div>
      </div>
      <!-- page breadcrumb -->
  </div>
</template>
<script>
import axios from 'axios';
export default {
  name: "Sub-Header",
  data(){
    return{
      title: null,
      breadcrumb: null,
      pageDetails:[
        {title:'Tutor Registration', path:'/tutor_registration', breadcrumb: 'Registration'},
        {title:'Find Courses', path:'/tutors', breadcrumb: 'Courses'},
        {title:'E-Shop', path:'/study_materials', breadcrumb: 'E-Shop'},
        {title:'Video Courses', path:'/video_course', breadcrumb: 'Video Courses'},
        {title:'Purchased Items', path:'/order', breadcrumb: 'order'},
        {title:'About Us', path:'/about-us', breadcrumb: 'About Us'},
        {title:'Privacy Policy', path:'/privacy-policy', breadcrumb: 'Privacy policy'},
        {title:'Refund Policy', path:'/refund-policy', breadcrumb: 'Refund policy'},
        {title:'Terms & Conditions', path:'/terms', breadcrumb: 'Terms & Conditions'},
        {title:'Staff Directory', path:'/staff', breadcrumb: 'Staff Directory'},
        {title:'Frequently Asked Questions', path:'/faq', breadcrumb: 'FAQ'},
        {title:'Academic Year 2023 - 2024', path:'/academic-year', breadcrumb: 'Academic Year'},
        {title:'Counselling Team Members AMCC', path:'/amccmembers', breadcrumb: 'Counselling Team Members AMCC'},
        {title:'Contact us', path:'/contact-us', breadcrumb: 'Contact Us'},
        {title:'Pricing', path:'/pricing', breadcrumb: 'Pricing'},
        {title:'Testimonials', path:'/testimonials', breadcrumb: 'Tetimonials'},
        {title:'Cart', path:'/cart', breadcrumb: 'Cart'},
        {title:'Gallery', path:'/gallery', breadcrumb: 'Gallery'},
      ],
      tutor:null,
      show_breadcrumb:true,
      tutor_head:'',
      tutor_detail:false,
    }
  },
  beforeMount(){
    this.pageDetails.forEach(detail => {
      if(detail.path == this.$route.path){
        this.title = detail.title;
        this.breadcrumb = detail.breadcrumb;
      }
    });
    if(this.$route.path == '/tutors/tutor-details'){
      this.tutor_detail = true;
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutor/'+this.$route.query.id,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        this.tutor=res.data.data;
        if(this.tutor.username){
          this.tutor_head = this.tutor.username;
          this.breadcrumb = this.tutor.username;
        }else{
          this.tutor.subjects.forEach((sub,index) => {
            if(sub.is_active == 'Y'&&sub.subject){
              this.tutor_head += sub.subject.subject_name;
              this.breadcrumb += sub.subject.subject_name;
              if(res.data.data.subjects.length != index+1){
                this.tutor_head += ','
                this.breadcrumb += ','
              }
            }
          });
        }
        // this.show_breadcrumb=true;
      })
    }
    if(this.$route.path == '/pricing/hourly-package'){
        this.title = '$'+this.$route.query.hour_price+'/Hr';
        this.breadcrumb = 'Hourly Package';
      // axios.get(process.env.VUE_APP_TUTION_SITE_API+'/pricing'+this.$route.query.price).then(res => {
      //   this.tutor=res.data.data;
      //   this.title = this.tutor.name;
      //   this.show_breadcrumb=false;
      // })
    }
    if(this.$route.path == '/pricing/monthly-package'){
        this.title = '$'+this.$route.query.month_price+'/Month';
        this.breadcrumb = 'Monthly Package';
      // axios.get(process.env.VUE_APP_TUTION_SITE_API+'/pricing'+this.$route.query.price).then(res => {
      //   this.tutor=res.data.data;
      //   this.title = this.tutor.name;
      //   this.show_breadcrumb=false;
      // })
    }
    if(this.$route.path == '/pricing/yearly-package'){
        this.title = '$'+this.$route.query.year_price+'/Year';
        this.breadcrumb = 'Yearly Package';
      // axios.get(process.env.VUE_APP_TUTION_SITE_API+'/pricing'+this.$route.query.price).then(res => {
      //   this.tutor=res.data.data;
      //   this.title = this.tutor.name;
      //   this.show_breadcrumb=false;
      // })
    }
    if(Object.keys(this.$route.params).includes('material')){
      this.title = this.$route.params.material;
      this.breadcrumb = this.$route.params.material;
    }
    if(Object.keys(this.$route.params).includes('video_course_title')){
      this.title = this.$route.params.video_course_title;
      this.breadcrumb = this.$route.params.video_course_title;
    }
  },
  watch:{
    $route (to){
      this.pageDetails.forEach(detail => {
      if(detail.path == to.path){
        this.title = detail.title;
        this.breadcrumb = detail.breadcrumb;
      }
    });
    }
  } 
}
</script>
<style scoped>
.page-header{
  border-bottom: transparent;
  background: #ce0d3d;
  min-height: 189px;
}
.page-caption{
  padding-top:30px;
  padding-bottom:32px;
}
.page-title{
      font-size: 46px;
    color: #fff;
    margin-bottom: 0px;
    font-weight: 400;
    line-height: 1.2;
}
.page-breadcrumb {
    background-color: rgba(0, 0, 0, .5);
    border-top: rgba(255, 255, 255, .1) 1px solid;
}
.page-breadcrumb .breadcrumb .breadcrumb-item {
    font-size: 13px;
}
.breadcrumb{
    margin-bottom: 8px;
    padding-left: 2px;
}
.page-breadcrumb .breadcrumb .breadcrumb-item .breadcrumb-link {
    color: #bebbc1;
    text-decoration: none;
}
.page-breadcrumb .breadcrumb .breadcrumb-item .breadcrumb-link:hover {
    color: #0079df;
}
</style>