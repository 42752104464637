<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="dashboard-page-header mb-0 pb-3">
            <h3 class="dashboard-page-title">Live Classes</h3>
            <div class="row" style="justify-content: space-between;padding-left: 12px;">
              <p class="col-4 col-lg-4 col-md-4 col-sm-4 p-0">List of live classes</p>
              <div class="col-6 col-lg-6 col-md-6 col-sm-6 p-0">
                <div class="row">
                  <div class="col-6 col-lg-6 col-md-6 col-sm-6 p-0">
                    <input type="date" v-model="toBook.start_date" id="day" name="day" :min="(new Date(Date.now())).toISOString().substr(0, 10)" class="form-control">
                  </div>
                  <div class="col-4 col-lg-4 col-md-4 col-sm-4 p-0">
                    <a  v-on:click="createLink" class="btn btn-default ml-3" id="trigger6" style="font-size:12px !important;">CREATE LINK</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0" v-if="live_classes.length >0">
          <div class="dashboard-list-block" v-for="b_class in live_classes" v-bind:key="b_class.id">
            <div class="row">
              <div class="col-xl-2 col-lg-4 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                <div class="dashboard-list-img">
                  <a :href="'/tutors/tutor-details?id='+b_class.tutor.tutor_id">
                    <img :src="b_class.tutor.profile_pic" alt="" class="img-fluid" v-if="b_class.tutor.profile_pic" style="pointer-events:none;">
                    <img src="../../assets/images/dummy-tutor.jpg" alt="" class="img-fluid" v-if="!b_class.tutor.profile_pic" style="pointer-events:none;">
                  </a> 
                </div>
              </div>
              <div class="col-xl-7 col-lg-5 col-md-6 col-sm-12 col-12 pt-0 pb-0">
                <div class="dashboard-list-content">
                    <h3 class="mb-0"><a :href="'/tutors/tutor-details?id='+b_class.tutor.tutor_id" class="title" v-if="b_class.tutor">{{b_class.tutor.name}} | {{b_class.subject?b_class.subject.subject_name:'-'}} | {{b_class.grade_id?getGrade(b_class.grade_id):'-'}} Grade</a></h3>
                    <p style="font-size:14px;">Class Time : {{b_class.date}} / {{b_class.time}}</p>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                <div class="dashboard-list-btn"><button v-on:click="getClassRoom(b_class.video_key)" class="btn btn-outline-violate btn-xs mr-2">JOIN CLASS</button></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-lg-12 empty-data text-center">
            No Live classes found
          </div>
        </div>
      </div>
    </div>
    <b-modal id="createLink" ref="add-modal" size="lg" title="Create Class Link" hide-footer hide-header centered>
      <div class="container" v-if="isLink">
        <div  class="row" style="justify-content: space-between;">
          <div class="col-lg-10 col-md-10 col-sm-10">
            <h2>Here's the link to your meeting</h2>
          </div>
          <div class="col-lg-1 col-md-1 col-sm-1" v-on:click="hideModal" style="text-align: center;padding: 0;background-color: #ebebeb;border: 1px solid #ebebeb;border-radius: 40px;width: 50px;height: 50px;cursor:pointer">
            <button style="font-size: 28px;">x</button>
          </div>
        </div>
        <div class="copy-text">
          <p>Copy this link and send it to people that you want to meet with. Make sure that you save it so that you can use it later, too.This link is valid only for one day.</p>
        </div>
        <div aria-live="polite" >
          <div class="copy-area">
            <div class="copy-link" jsname="DkF5Cf">{{link}}</div>
            <div jscontroller="p7ROLc" jsaction="rcuQ6b:npT2md;" data-link-only="true">
              <span data-is-tooltip-wrapper="true">
                <button class="" v-on:click="copyLink">Copy</button>
              </span>
            </div>
          </div>
        </div>
        <p v-if="copied==true" class="text-success">Link copied into clipboard</p><p v-else-if="copied==false" class="text-danger">An error occured</p>
        <!-- <div style="text-align: right;">
          <b-button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal">Cancel</b-button>
        </div> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import copy from 'copy-to-clipboard';

export default {
  name: "admin-live-classes",
  data(){
    return {
      live_classes:[],
      grades:[],
      valid:true,
      loading:false,
      isLink : false,
      link:null,
      message:'',
      toBook:{
        start_date:null
      },
      copied:null
    }
  },
  beforeMount(){
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/listLiveClasses',{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
      if(res.data.message == 'live class retrived sucessfully'){
        res.data.data.forEach(live => {
          if(live.is_active == 'Y' && this.checkLive(live)){
            this.live_classes.push(live);
          }
        })
      }else{
        this.live_classes = [];
      }
    });
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/grades',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
      if(res.data.Grades.length){
        res.data.Grades.forEach(g => {
          if(g.is_active == 'Y'){
            this.grades.push({value:g.grade_id,text:g.name});
          }
        })
      }
    })
  },
  methods:{
    getGrade(g){
      let result;
      this.grades.forEach(grade => {
        if(grade.id == g){
          result =grade.value;
        }
      });
      return result;
    },
    getClassRoom(key){
      window.location.href = process.env.VUE_APP_LIVE_CLASS_URL+'/'+key;
    },
    hideModal(){
      this.$refs['add-modal'].hide();
    },
    createLink(){
      this.link = null;
      this.isLink = false;
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/add/link',{start_date:this.toBook.start_date},{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        this.loading = false;
        this.message = '';
        this.$refs['add-modal'].show();
        if(res.data.status == 1){
          this.isLink = true;
          this.message = 'Link Created';
          this.link = res.data.data.video_key;
        }else{
          this.message = 'Error';     
        }
      })
    },
    copyLink(){
      var ua = navigator.userAgent.toLowerCase(); 
      if (ua.indexOf('safari') != -1) { 
        if (ua.indexOf('chrome') > -1) {
          navigator.clipboard.writeText(process.env.VUE_APP_LIVE_CLASS_URL+'/'+this.link);
          this.copied = true;
        } else {
          copy(process.env.VUE_APP_LIVE_CLASS_URL+'/'+this.link);
          this.copied = true;
        }
      }
    },
    checkLive(u_class){
      var hours = new Date().getHours();
      var minutes = new Date().getMinutes();
      let start_hour = parseInt(u_class.start_time.split(':')[0]);
      let start_minute = parseInt(u_class.start_time.split(':')[1]);

      let end_hour = parseInt(u_class.end_time.split(':')[0]);
      let end_minute = parseInt(u_class.end_time.split(':')[1]);

      let start_timestamp = new Date(u_class.start_date).setHours(start_hour,start_minute,0,0);
      let end_timestamp = new Date(u_class.end_date).setHours(end_hour,end_minute,0,0);
      let current_timestamp = new Date().setHours(hours,minutes,0,0);

      if((start_timestamp<=current_timestamp && current_timestamp<=end_timestamp)){
          return true;
      }else{
        return false;
      }
    }
  }
}
</script>

<style scoped>
.dashboard-list-block {
    background-color: #fff;
    margin-bottom: 20px;
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    border-radius: 3px;
}
.dashboard-list-block .row {
  align-items:center;
}
.dashboard-list-img img {
    width: 100%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.dashboard-list-content {
    font-size: 14px;
    padding-top: 25px;
    padding-bottom: 25px;
}
.list-title {
    color: #252328;
    text-decoration: none;
}
.text-danger {
    color: #dc3645 !important;
}
.text-success {
    color: #28a745 !important;
}
p:last-child {
    margin: 0px;
}
.dashboard-list-btn {
    padding: 29px;
    text-align: right;
}
.btn-outline-violate:hover {
    color: #fff;
    background-color: #9047fb;
    border-color: #9047fb;
}
.btn-outline-violate {
    color: #9047fb;
    background-color: #f2e9ff;
    border-color: #9047fb;
}
.btn-xs {
    padding: 4px 8px !important;
    font-size: 11px !important;
    line-height: 1.5;
}
.btn {
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 2px;
    text-transform: uppercase;
    letter-spacing: 1px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    word-wrap: break-word;
    white-space: normal !important;
}
.title {
    color: #252328;
    text-decoration: none;
}
.title:hover {
    outline: 0;
    text-decoration: none;
}
.title:hover {
    color: #ce0d3d;
    text-decoration: none;
}
.copy-text{
  color: rgb(95,99,104);
  margin-bottom: 0.75rem;
}
.copy-area{
  align-items: center;
  background: rgb(241,243,244);
  border-radius: 4px;
  color: rgb(95,99,104);
  display: flex;
  padding-left: 0.75rem;
  padding: 15px;
}
.copy-link{
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: .00625em;
  font-weight: 400;
  color: rgb(32,33,36);
  flex-grow: 1;
}
</style>