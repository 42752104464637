<template>
  <section class="chat-pool-section">
    <div class="chat-pool">
      <div class="pool_header pl-5">
        <h5 role="button" v-on:click="goToHome()">Fromyourtutor Chat Pool</h5>
        <p style="font-size:12px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis" v-if="members">You,{{members.toString()}}</p>
      </div>
      <div class="pool_list pl-5 pr-5 pt-5" ref="contentContainer">
        <div class="text-center container col-5 mt-2" style="font-size:14px;background-color: #f4f3da;">Welcome to fromyourtutor chatpool. Please don't share your personal email id, contact number whatsapp number etc..</div>
        <div class="mb-5" v-for="message in messages" :key="message.id" :class="message.myself?'chat-darker':'chat-lighter '" v-chat-scroll="{always:false,smooth:true}">
          <div class="sender">{{message.participantName}}</div>
          <p class="mb-0"><span v-if="message.myself&&message.recipient" style="color: #0d6efd;font-size: 14px">{{'@'+message.recipient+' '}}</span>{{message.content}} 
          </p>
            <a class="btn btn-success btn-xs text-white mt-2" v-if="!message.myself&&message.id!=0" v-on:click.prevent="toReplay(message.uid,message.participantName)">Click to Replay</a>
        </div>
      </div>
      <div class="pool_footer">
        <div class="row">
          <div class="col-md-8 textarea-container">
            <div style="background-color:white;">
              To:
              <select v-model="recipient_id" name="replyTo" class="reply_select">
                <option value='0'>Everyone</option>
                <option v-for="(participant,index) in participants" :value="participant.id" :key="index">{{participant.name}}</option>
              </select>                  
              <textarea v-model="message" placeholder="Type here..." outlined>
                <a href="javascript:void(0);" class="btn btn-outline-secondary pull-right text-white" v-on:click="selectFile">
                  <i class="fa fa-paperclip"></i>
                </a>
                <input type="file" id="attachment" style="display:none;" v-on:change="fileSelected">
              </textarea>
            </div>
          </div>
          <div class="col-md-2">
            <input v-on:click="onMessageSubmit(message)" type='button' value='Send' id='but_read' class="btn btn-success ml-3">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script></script>
<script>
import axios from 'axios';
import { io } from 'socket.io-client';

export default {
  name:'chat-pool',
  data(){
    return{
      type:'',
      token: null,
      chats:[],
      visible: true,
      participants: [],
      message:null,
      recipient_id:0,
      myself: {
          name: null,
          id: null,
      },
      colors: {
        header: {
            bg: '#d30303',
            text: '#fff'
        },
        message: {
            myself: {
                bg: '#fff',
                text: '#bdb8b8'
            },
            others: {
                bg: '#fb4141',
                text: '#fff'
            },
            messagesDisplay: {
                bg: '#f7f3f3'
            }
        },
        submitIcon: '#b91010',
        submitImageIcon: '#b91010',
      },
      messages:[],
      members:null,
      replyTo:null,
      attachment:null,
      app:null,
      tokens: [],
      socket:null,
      page:1,
      in_loading: true
    }
  },
  
  beforeMount(){
    if(this.$cookies.get('tution-admin') || this.$cookies.get('tution-tutor') || this.$cookies.get('tution-student')){
      if(this.$cookies.get('tution-student')){
        this.token = this.$cookies.get('tution-student').token;
        this.type = 'student';
      }else if(this.$cookies.get('tution-tutor')){
        this.token = this.$cookies.get('tution-tutor').token;
        this.type = 'tutor';
      }else if(this.$cookies.get('tution-admin')){
        this.token = this.$cookies.get('tution-admin').token;
        this.type = 'admin';
      }else{
        this.$router.push("/login");
      }
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/user',{headers:{"Authorization":'bearer '+this.token}}).then(res => {
        if(res.status){
          this.myself.name = res.data.user.name;
          this.myself.id = res.data.user.user_id;
          this.myself.type = this.type;
        }
      });
      this.listChats();
    }else{
      this.$router.push("/login");
    }
  },

  mounted() {
    this.scrollToBottom();
  },
  updated() {
    this.scrollToBottom();
  },

  created() {
    this.socket = io(process.env.VUE_APP_SIGNAL_SERVER_URL);
    this.socket.emit('join-room',{roomId:this.$route.params.key});

    this.socket.on('send-message', (message) => {
      if(message.uid != this.myself.id  && (message.participantId == 0 || message.participantId == this.myself.id || this.type === 'admin')){
        this.messages.push(message);
      }
    });
  },

  methods:{
    fileSelected(){
      const files = event.target.files;
      if(files.length>0){
        this.attachment = files[0];
      }
    },
    selectFile(){
      document.getElementById("attachment").click();
    },
    listChats(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/list_chats',{headers:{"Authorization":'Bearer '+this.token}}).then(res => {
        if(res.data.status){
          res.data.participants.forEach(participant =>{
            if(this.$cookies.get('tution-tutor')){
              this.participants.push({name:participant.user.name,id:participant.user.user_id,token:participant.user.remember_token});
            }else{
              this.participants.push({name:participant.name,id:participant.user_id,token:participant.remember_token});
            }
          });
          this.getMembers();
          this.getTokens();
          if(res.data.chats.length){
            res.data.chats.forEach(chat => {
              this.messages.push({id:chat.id,content:chat.message,uid:chat.uid,myself:(chat.uid==this.myself.id?true:false),participantName:chat.user.name,participantId:(chat.participantId==this.myself.id?this.myself.id:chat.recipient_id),recipient:((chat.recipient)?chat.recipient.name:null),uploaded:true,viewed:true,type:'text'});
            });
          }
        }
      });
    },
    onMessageSubmit: function (message) {
      var content = {
        message: message,
        recipient_id: parseInt(this.recipient_id),
        type: this.myself.type,
        uid: this.myself.id,
      }      
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/store/chat',content,{headers:{"Authorization":'Bearer '+this.token}}).then(res => {
        if(res.data.status){
          var data = res.data.message;
          this.messages.push({id:data.id,content:data.message,uid:data.uid,myself:true,participantName:data.user.name,participantId:data.recipient_id,recipient:((data.recipient)?data.recipient.name:null),uploaded:true,viewed:true,type:'text'}); 
          try {
            this.socket.emit('send-message', {id:data.id,content:data.message,uid:data.uid,myself:false,participantName:data.user.name,participantId:data.recipient_id,recipient:((data.recipient)?data.recipient.name:null),uploaded:true,viewed:true,type:'text'});
          } catch (error) {
            console.error('Error emitting "sendChatToServer" event:', error);
          }  

          this.message = null;
          const options = {
            method: 'POST',
            url: 'https://onesignal.com/api/v1/notifications',
            headers: {
              accept: 'application/json',
              Authorization: 'Basic '+process.env.VUE_APP_REST_API_KEY,
              'content-type': 'application/json'
            },
            data: {
              app_id:process.env.VUE_APP_ONESIGNAL_APP_ID,
              include_player_ids: this.tokens,
              contents: {en: data.message},
              name: data.user.name+' sent you'
            }
          };

          axios.request(options).then(function (response) {
            console.log(response.data);
            // socket.emit('chat message', response.data);
          })
          .catch(function (error) {
            console.error(error);
          });
        }
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const contentContainer = this.$refs.contentContainer;

        if (contentContainer) {
          contentContainer.scrollTop = contentContainer.scrollHeight;
          this.in_loading = false;
        }
      })
    },
    onClose() {
        this.visible = false;
    },
    getMembers(){
      this.members = this.participants.map(function(participant){
        return participant['name'];
      })
    },
    getTokens(){
      this.tokens = [];
      this.tokens = this.participants.filter(function (participant) {
        return (participant.token != null && participant.token != '');
      }).map(function (participant) {
          return participant.token;
      })
    },
    toReplay(id,name){
      console.log(id,name);
      this.recipient_id = id;
    },
    goToHome(){
      window.location.href='/'
    }
  }
}
</script>

<style scoped>
/* .chat-pool-section{
    background: url(../assets/images/couple-bg-img.jpg)no-repeat;
    background-size: cover;
    height: 100%;
    box-shadow: 0px 1px 20px #6c1227;
} */
html{
  overflow-y: hidden !important;
}
.payment-block {
    margin: 30px auto;
    min-height: 93.6vh;
}
.payment-block .auth-box {
    margin: 20px auto 0;
    max-width: 450px !important;
}
.chat-pool{
  position: fixed;
  height: 100%;
  width: 100%;
  /* margin-left: -74px;
  margin-top: -56px; */
}
.pool_header{
  background-color: #ce0d3c;
  color: white;
  height: 60px;
  padding: 5px;
}
.pool_header > h5{
  color: white;
  font-weight: bold;
}
.pool_footer {
  position: fixed;
  width: 100%;
  bottom: 0px;
  padding-left: 25px;
  background-color: #d4d4d4;
}
.pool_list{
  overflow-y: scroll;
  position: absolute;
  height: -webkit-fill-available;
  width: 102%;
  margin-bottom: 79px;
}
textarea{
  width: 100%;
  padding: 5px;
  background-color: white;
  font-size: 12px;
}
.chat-lighter {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  margin: 10px 0;
  width: fit-content;
}

/* Darker chat container */
.chat-darker {
  border-color: #ccc;
  background-color: #ddd;
  border-radius: 10px 10px 0px 10px;
  margin-left: auto;
  width: fit-content;
  padding: 10px;
  /* margin: 10px 0; */
  /* margin-left:0; */
}

/* Clear floats */
.chat-container::after {
  content: "";
  clear: both;
  display: table;
}

/* Style images */
.chat-container img {
  float: left;
  max-width: 60px;
  width: 100%;
  margin-right: 20px;
  border-radius: 50%;
}

/* Style the right image */
.chat-container img.right {
  float: right;
  margin-left: 20px;
  margin-right:0;
}

/* Style time text */
.time-right {
  float: right;
  color: #aaa;
}

/* Style time text */
.time-left {
  float: left;
  color: #999;
}
.sender{
  font-size: 12px;
  color: #ce0d3c;
}
/* .textarea-container {
  position: relative;
}
.textarea-container textarea {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.textarea-container > .replay-tag {
  position: absolute;
  top: 0;
  right: 0;
} */
/* .dropdown
{
	position: absolute;
  z-index: 9999;
  top: 12px;
  left: 12px;
  border: 1px solid #878787;
  background-color: #c5c5c5;
} */
/* body textarea
{
	width: 500px;
	height: 200px;
} */
.reply_select{
  font-size: 14px;
  border: 1px solid #00000091;
  background-color: #878686;
  border-radius: 5px;
  color: white;
}
</style>