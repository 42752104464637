<template>
  <div class="study_materials">
    <div class="dashboard-content">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
            <div class="dashboard-page-header mb-0 pb-3">
              <h3 class="dashboard-page-title">Video Courses</h3>
              <p class="d-block">Add, Edit or Delete Video Courses</p>
            </div>
          </div>
        </div>
        <div class="row">   
          <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 setbtn">
            <router-link to="/tutn-auth/add-video-courses" class="btn btn-default" style="font-size:12px !important;">ADD COURSE</router-link>
          </div>
        </div> <br>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="card request-list-table table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Image</th>
                    <th>Course Title</th>
                    <th>Price</th>
                    <th>No of Modules</th>
                    <th>Active</th>
                    <th>Action</th>
                    <th>Sale</th>
                  </tr>
                </thead>
                <tbody v-if="courses.length">
                  <tr v-for="(course,index) in courses " :key="index">
                    <td>{{index+1}}</td>
                    <td>
                      <img :src="course.thumbnails[0].url" style="width: 80px;pointer-events:none;" v-if="course.thumbnails.length">
                    </td>
                    <td> {{course.name}}</td>
                    <td>${{course.price}}</td>
                    <td class="text-center">{{course.modules.length}}</td>
                    <td>
                      <div class="switch-notification" >
                        <label class="switch">
                          <input type="checkbox" :id="'activate-profile'+index" @click="activateCourse(course.id,index)" :checked="course.flag==1?true:false">
                          <span class="slider"></span>
                        </label>
                      </div>
                    </td>
                    <td>
                      <a class="btn btn-outline-violate btn-xs mr-1 mb-1" v-on:click.prevent="viewCourseDetails(course.id)" style="width:86px;">View</a>
                      <a class="btn btn-outline-violate btn-xs mr-1 mb-1" v-on:click.prevent="editVideoCourse(course.id)" style="width:86px;">EDIT</a>
                      <a class="btn btn-outline-violate btn-xs mb-1" v-on:click.prevent="openShareModal(course.id,course.name,index)" style="width:86px;">SHARE</a>
                      <a class="btn btn-outline-pink btn-xs" v-on:click.prevent="confirmDelete(course.id,index)" style="width:86px;">DELETE</a>
                    </td>
                    <td>{{course.sales_count}}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="empty-data">
                    <td></td>
                    <td></td>
                    <td>No materials listed</td>
                  </tr>
                </tbody>
              </table>
              <div class="col pt-0" v-if="courses.length">
                <v-pagination v-model="page" :length="length" class="float-left" @input="handlePageChange"></v-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="confirmation" ref="confirm-modal" title=" " hide-footer hide-header centered>
      <div class="container">
        <div class="icon-box text-center"><i class="far fa-times-circle"></i></div>		
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Are you sure?</h4>	
        <div class="modal-body text-center">
          <p>Do you really want to delete these records? This process cannot be undone.</p>
        </div>		
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal('confirm-modal')">Cancel</button>
            <button type="button" class="btn btn-danger" v-on:click="deleteCourse">Delete</button>
        </div>
      </div>
    </b-modal>
    <b-modal id="alert" ref="alert-modal" title=" " hide-footer hide-header centered body-bg-variant="success">
      <div class="container">
        <p>{{message}}</p>
      </div>
    </b-modal>
    <b-modal id="share" ref="share-modal" title="Share Course" hide-footer hide-header centered>
      <div class="container">
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Share Course</h4>	
        <div class="modal-body text-center" v-if="share.id">
          <p v-if="share.name"><strong>Course:</strong>{{share.course_name}}</p>
          <p>Please enter the email address do you want to share</p>
          <v-form ref="shareForm" lazy-validation v-model="valid">
            <v-text-field type="email" :rules="emailRules" v-model="share.email" @keydown.space.prevent required outlined><template #label><span style="font-size:18px !important;">Email<span class="red--text"><strong> * </strong></span></span></template></v-text-field>
            <v-text-field type="text" :rules="nameRules" v-model="share.name" required outlined><template #label><span style="font-size:18px !important;">Name</span></template></v-text-field>
            <v-text-field type="text" :rules="numberRules" v-model="share.amount" @keydown.space.prevent required outlined><template #label><span style="font-size:18px !important;">Amount</span></template></v-text-field>

          </v-form>
        </div>	
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal('share-modal')">Cancel</button>
            <button type="button" class="btn btn-danger" v-on:click="shareCourse">Share</button>
            <button type="button" class="btn btn-danger" v-on:click="copyCourse">Copy Link</button>
            <p v-if="order_success==true" class="text-success">Email sent.</p><p v-else-if="order_success==false" class="text-danger">An error occurred</p>
            <p v-if="copied==true" class="text-success">Link copied into clipboard</p><p v-else-if="copied==false" class="text-danger">An error occured</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios';
import AWS from "aws-sdk";
import copy from 'copy-to-clipboard';
export default {
  name: "admin-shop",
  data(){
    return {
      courses:[],
      page:1,
      length:0,
      offset: 0,
      limit: 25,
      total: 0,
      record:{
        id:null,
        index:null,
        method:null,
        type:null
      },
      share:{
        id:null,
        email:null,
        index:null,
        name:null,
        amount:0
      },
      order_success:null,
      activate:null,
      message:null,
      valid:true,
      emailRules:[
        v => !!v || 'Enter valid email',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
      ],
      nameRules:[
        v => !!v || 'Enter valid name',
      ],
      numberRules:[
        v => !!v || 'Enter valid amount',
        v => /\D/g.test(v) == false || 'Enter number only',
      ],
      copied:null
    }
  },
  beforeMount(){
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/admin/video_courses?page='+this.page+'&per_page='+this.limit,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
      if(res.data.courses.data.length>0){
        res.data.courses.data.forEach(course => {
          this.courses.push(course);
        });
        this.limit = res.data.courses.per_page;
        this.offset = res.data.courses.from-1;
        this.total = res.data.courses.total;
        this.length = Math.ceil(res.data.courses.total/res.data.courses.per_page);
      }
    })
  },
  methods:{
    hideModal(type){
      this.$refs[type].hide();
    },
    confirmDelete(id,index){
      this.record.id = id;
      this.record.index = index;
      this.$refs["confirm-modal"].show();
    },
    viewCourseDetails(id){
      const data = this.$CryptoJS.AES.encrypt('tuition_id='+id, 'admn@369').toString().replaceAll('/','Por21Ld');
      this.$router.push('/tutn-auth/video-class-room/'+data);
    },
    editVideoCourse(id){
      this.$router.push('/tutn-auth/edit/video-course/'+id);
    },
    activateCourse(id,index){
      this.activate = (document.getElementById('activate-profile'+index).checked == true)?1:0;
      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/activate/video_course/'+this.activate+'/'+id,{},{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res=>{
        if(res.data.status){
          this.message = res.data.message;
        }
      })
    },
    searchURL(){
      this.urls = [];
      this.courses=[];
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/video_courses?page='+this.page+'&per_page='+this.limit).then(res => {
        if(res.data.products.data.length>0){
          res.data.courses.data.forEach(course => {
            this.courses.push(course);
          });
          this.limit = res.data.courses.per_page;
          this.offset = res.data.courses.from-1;
          this.total = res.data.courses.total;
          this.length = Math.ceil(res.data.courses.total/res.data.courses.per_page);
        }else{
          this.limit = res.limit;
          this.offset = res.offset;
          this.total = res.total;
          this.urls=[];
        }
      });
    },
    handlePageChange(value) {
        this.page = value;
        this.offset = this.limit * (this.page - 1);
        this.searchURL()
    },
    deleteCourse(){
      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/disable/video_course/'+this.record.id,{},{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.status){
          this.$refs['confirm-modal'].hide();
          this.courses.splice(this.record.index,1);
        }
      })
    },
    openShareModal(id,name,index){
      this.share.id = id;
      this.share.index = index;
      this.share.course_name = name;
      this.$refs['share-modal'].show();
    },
    copyCourse(){
      this.valid = this.$refs.shareForm.validate();
      if(this.valid == true){
        axios.post(process.env.VUE_APP_TUTION_SITE_API+'/share/video_course',this.share,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
          if(res.data.status == true){
            var ua = navigator.userAgent.toLowerCase(); 
            if (ua.indexOf('safari') != -1) { 
              if (ua.indexOf('chrome') > -1) {
                navigator.clipboard.writeText('https://fromyourtutor.com/order?key='+res.data.transactionID+'&&type=course');
                this.copied = true;
              } else {
                copy('https://fromyourtutor.com/order?key='+res.data.transactionID+'&&type=course');
                this.copied = true;
              }
            }
            setTimeout(() => {
              this.$refs['share-modal'].hide();
            },5000);
          }else{
            this.copied = false;
          }
        })
      }
    },
    shareCourse(){
      this.valid = this.$refs.shareForm.validate();
      if(this.valid == true){
        axios.post(process.env.VUE_APP_TUTION_SITE_API+'/share/video_course',this.share,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
          if(res.data.status == true){
            var access_key = process.env.VUE_APP_SES_ACCESS_KEY;
            var secret_key = process.env.VUE_APP_SES_SECRET_KEY;
            let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
            AWS.config.update({
              accessKeyId: keys.accessKeyId,
              secretAccessKey: keys.secretAccessKey,
              region: 'ap-south-1'
            })
            var self = this;
            var params = {
              Destination: { /* required */
                // CcAddresses: [
                //   self.student.user.email,
                //   /* more items */
                // ],
                ToAddresses: [
                  this.share.email,
                  'admin@fromyourtutor.com'
                  /* more items */
                ]
              },
              Message: { /* required */
                Body: { /* required */
                  Html: {
                    Charset: "UTF-8",
                    Data: '<table style="width: 600px; margin: 0 auto;" width="600"><tr><td style="text-align: center;"><br><img src="https://tution-site.s3.ap-south-1.amazonaws.com/test/logo.png" alt="logo"><br></td></tr><tr><td><br><table cellpadding="10" style="background:#f5f5f5; border-radius: 15px; width:560px; margin:0 auto; "><tr><td style="font-family: arial; font-size:18px; text-align: center; color: #086e00;"><br>	Your order has been successfully placed.</td></tr><tr><td style="font-family: arial; font-size:20px; text-align: center; color: #000;"><strong>Course:'+self.share.course_name+'</strong><br></td></tr><tr><td style="font-family: arial; font-size:16px; text-align: center; color: #000; line-height: 28px;"><a href="https://fromyourtutor.com/order?key='+res.data.transactionID+'&&type=course" target="_blank" style="color: #fff; padding: 15px; font-size:16px; background:#ce004b; border-radius:5px; text-decoration: none;"><strong>Watch Course</strong></a><br><br></td></tr></table></td></tr></table>'
                  },
                  // Text: {
                  //   Charset: "UTF-8",
                  //   Data: 'Your order has been successfully placed.',
                  // }
                },
                Subject: {
                  Charset: 'UTF-8',
                  Data: 'Order placed'
                }
                },
              Source: 'info@fromyourtutor.com', /* required */
              ReplyToAddresses: [
                'info@fromyourtutor.com',
                /* more items */
              ],
            };

            // Create the promise and SES service object
            var sendPromise = new AWS.SES({apiVersion: '2010-12-01'}).sendEmail(params).promise();

            // Handle promise's fulfilled/rejected states
            sendPromise.then(
              function(data) {
                if(data.MessageId){
                  console.log('Email send');
                  self.order_success = true;
                  setTimeout(() => {
                    self.$refs['share-modal'].hide();
                  },5000)
                }
              }).catch(
              function(err) {
                console.error(err, err.stack);
                self.order_success = false;
            });
          }
        })
      }
    }
  }
}
</script>

<style scoped>
p:last-child {
    margin: 0px;
}
.slide-panel-light {
    background-color: #fff;
    padding: 30px;
    text-align: left;
    overflow: scroll;
}
.slide-panel{
    position: fixed;
    width: 0px;
    transition: all 450ms ease 0s;
    height: 100%;
    top: 0px;
    right: -275px;
    z-index: 1049;


}
.setbtn a.btn {
    float: right !important;
    width: 200px;
}
.btn-default {
    color: #fff;
    background-color: #0079df;
    border-color: #0079df;
}
.btn {
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 2px;
    text-transform: uppercase;
    letter-spacing: 1px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    word-wrap: break-word;
    white-space: normal !important;
}
.card {
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    margin-bottom: 30px;
    border-radius: 2px;
}
.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}
.request-list-table .table {
    font-size: 14px;
}
.table {
    margin-bottom: 0px;
    color: #6f6d72;
}
.request-list-table table th {
    background-color: #fff;
    font-weight: 400;
    border-bottom: 1px solid #eee;
    padding: 14px 20px;
    color: #252328;
}
.table thead th {
    vertical-align: bottom;
    font-weight: normal;
}
.request-list-table table td {
    background-color: #fdfdfd;
    border-bottom: 1px solid #eee;
    padding: 10px 16px;
}
.table td, .table th {
    padding: 8px;
    vertical-align: baseline;
    border-top: none;
    font-weight: normal;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn-xs {
    padding: 4px 8px !important;
    font-size: 11px !important;
    line-height: 1.5;
}
.btn-outline-violate {
    color: #9047fb;
    background-color: #f2e9ff;
    border-color: #9047fb;
}
.btn-outline-pink {
    color: #ff7b7b;
    background-color: #ffeded;
    border-color: #ff7b7b;
}
.btn-outline-violate:hover {
    color: #fff;
    background-color: #9047fb;
    border-color: #9047fb;
}
.btn-outline-pink:hover {
    color: #fff;
    background-color: #ff7b7b;
    border-color: #ff7b7b;
}
.btn-default:not(:disabled):not(.disabled).active, .btn-default:not(:disabled):not(.disabled):active, .show>.btn-default.dropdown-toggle {
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
.btn-default:hover{
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
.form-control {
    display: block;
    width: 100%;
    padding: 14px 16px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #252328;
    background-color: #fff;
    background-image: none;
    border: 1px solid #cfced0;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    /* background: #fff url(data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E) no-repeat right .75rem center; */
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430;
}
.btn-success {
    color: #fff !important;
    background-color: #28a745 !important;
    border-color: #28a745 !important;
}
.btn {
    padding: 10px 20px !important;
    font-size: 12px !important;
    font-weight: 500;
    border-radius: 2px;
    text-transform: uppercase !important;
    letter-spacing: 1px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    word-wrap: break-word;
    white-space: normal !important;
}
.switch {
    display: inline-block;
    width: 40px;
    height: 20px;
    transform: translateY(20%);
    position: relative;
}
label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #5f5e61;
    line-height: 2.3;
}
.switch input {
    display: none;
}
.slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 20px;
    cursor: pointer;
    transition: .4s;
    background: #bdbdbd;
}
.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: white;
  transition: .4s;
  border-radius: 20px;
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
input:checked + .slider:before {
  transform: translateX(20px);
}
input:checked+.slider {
    background: #129e32;
}
</style>