<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="dashboard-page-header mb-0 pb-3">
            <h3 class="dashboard-page-title">Chat Pool Settings</h3>
            <div class="row">
              <div class="col-5">
                <p class="d-block mb-2">View all tutors and students in chat pool</p>
              </div>
              <div class="col-2">
                <a href="/chat-pool" class="btn btn-default" id="trigger6" style="font-size:12px !important;">Chat Pool</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <v-autocomplete v-model="group.tutor_id" :items="tutors" item-text="text" item-value="value" id='selUser1' style='line-height: 30px !important;' placeholder="Select and Add Tutor" outlined></v-autocomplete>
        </div>
        <div class="col-4">
          <a v-on:click="addGroup()" class="btn btn-default ml-3" id="trigger6" style="font-size:12px !important;">Add Tutor</a>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row" style="background: #fff; padding: 20px 5px 20px 5px;  margin: 30px 0px 0px 0px;" v-for="(group,index) in groups" :key="index">
            <div class="col-md-5">
              <div class="card">
                <div class="card-body">
                  <p>{{group.user.name}}<br>
                    <small>{{group.user.email}}</small>
                  </p>
                  <div class="row">
                    <div class="col-md-8">                  
                      <v-autocomplete v-model="member[index]" :items="members" item-text="text" item-value="value" placeholder="Select Student" outlined></v-autocomplete>
                    </div>
                    <div class="col-md-2">
                      <input v-on:click="addMember(group.id,member[index],index)" type='button' value='Add' id='but_read' class="btn btn-default ml-3">
                    </div>
                    <div v-if="error[index]">{{error[index]}}</div>
                  </div>
                  <a v-on:click="confirmDelete(group.id,index)" class="btn btn-danger text-white" id="trigger6" style="font-size:12px !important;border-radius:25px !important;">DELETE TUTOR</a>
                  <!-- <a v-on:click="addMember(group.id,member[index])" class="btn btn-default ml-3" id="trigger6" style="font-size:12px !important;">Add</a> -->
                  <br/>
                </div>
              </div>
            </div>
            <div class="col-md-7">
              <div class="card" style="margin: 0px;margin-bottom:5px;" v-for="(member,i) in group.members" :key="i">
                <div class="card-body" style="padding:10px 5px 5px 10px">
                  <div class="row">
                    <div class="col-md-6">
                      {{member.user.name}} <br><small>{{member.user.email}}</small>
                    </div>
                    <!-- <div class="col-md-3">
                      <small>Tutor Time</small><br>
                      <div class="row">
                        <div class="form-group col-md-8" style="padding-right:0px">
                          <input style="padding:5px 5px 5px 5px" class="form-control" type="Time" :id="'tutor_time'+member.id"
                            placeholder="Class Time" @input="setTime('T',group.id,member.id)" :value="member.tutor_time?member.tutor_time.split(' ')[0]:null">
                        </div>
                        <div class="form-group col-md-4" style="padding-left:5px; padding-right:0px">
                          <select class="form-control" :id="'tutorTimeType'+member.id"
                            style="padding:5px 5px 5px 5px; height:33px" @input="setTime('T',group.id,member.id)">
                            <option value="AM" :selected="member.tutor_time?(member.tutor_time.split(' ')[1]=='AM'?true:false):true">AM</option>
                            <option value="PM" :selected="(member.tutor_time&&member.tutor_time.split(' ')[1]=='PM')?true:false">PM</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <small>Student Time</small><br>
                      <div class="row">
                        <div class="form-group col-md-8" style="padding-right:0px;">
                          <input style="padding:5px 5px 5px 5px" class="form-control" type="Time" :id="'student_time'+member.id"
                            placeholder="Class Time" @input="setTime('S',group.id,member.id)" :value="member.student_time?member.student_time.split(' ')[0]:null">
                        </div>
                        <div class="form-group col-md-4" style="padding-left:5px;  padding-right:0px">
                          <select class="form-control" :id="'studentTimeType'+member.id"
                            style="padding:5px 5px 5px 5px; height:33px" @input="setTime('S',group.id,member.id)">
                            <option value="AM" :selected="member.student_time?(member.student_time.split(' ')[1]=='AM'?true:false):true">AM</option>
                            <option value="PM" :selected="(member.student_time&&member.student_time.split(' ')[1]=='PM')?true:false">PM</option>
                          </select>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-5">
                          <a class="btn btn-outline-pink btn-xs" v-on:click="openTimeModal(group,member)">ADD TIME</a>
                        </div>
                        <div class="col-md-4">
                          <a class="btn btn-outline-pink btn-xs mb-2" v-on:click="confirmDeleteMember(member.id,i,group.id,index)">DELETE</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal id="confirmation" ref="confirm-modal" title=" " hide-footer hide-header centered>
        <div class="container">
          <div class="icon-box text-center"><i class="far fa-times-circle"></i></div>		
          <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Are you sure?</h4>	
          <div class="modal-body text-center">
            <p>Do you really want to delete these records?</p>
          </div>		
          <div class="modal-footer justify-content-center" style="border-top:none;">
              <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal('confirm-modal')">Cancel</button>
              <button type="button" class="btn btn-danger" v-if="record.type == 'Group'" v-on:click="deleteTutor">Delete</button>
              <button type="button" class="btn btn-danger" v-if="record.type == 'Member'" v-on:click="deleteMember">Delete</button>
          </div>
        </div>
      </b-modal>
      <b-modal id="confirm-time-modal" ref="confirm-time-modal" title="Add Time & Date " hide-footer centered>
        <div class="container">
          <div class="row">
            <div class="col-md-5">
              <small>Tutor Time<span style="font-size:10px;color:red;">*</span></small><br>
              <div class="row">
                <div class="form-group col-md-8" style="padding-right:0px">
                  <input style="padding:5px 5px 5px 5px" class="form-control" type="Time" placeholder="Class Time" v-model="toAdd.tutor_time">
                  <div v-if="errors.tutor_time" style="color:red;font-size:10px;">{{errors.tutor_time}}</div>
                </div>
                <div class="form-group col-md-4" style="padding-left:5px; padding-right:0px">
                  <select class="form-control" style="padding:5px 5px 5px 5px;" v-model="toAdd.tutor_type">
                    <option value="AM" :selected="toAdd.tutor_time?(toAdd.tutor_type=='AM'?true:false):true">AM</option>
                    <option value="PM" :selected="(toAdd.tutor_time&&toAdd.tutor_type=='PM')?true:false">PM</option>
                  </select>
                  <div v-if="errors.tutor_type" style="color:red;font-size:10px;">{{errors.tutor_type}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <small>Student Time<span style="font-size:10px;color:red;">*</span></small><br>
              <div class="row">
                <div class="form-group col-md-8" style="padding-right:0px;">
                  <input style="padding:5px 5px 5px 5px" class="form-control" type="Time" v-model="toAdd.student_time" placeholder="Class Time">
                  <div v-if="errors.student_time" style="color:red;font-size:10px;">{{errors.student_time}}</div>
                </div>
                <div class="form-group col-md-4" style="padding-left:5px;  padding-right:0px">
                  <select class="form-control" style="padding:5px 5px 5px 5px; height:33px" v-model="toAdd.student_type">
                    <option value="AM" :selected="toAdd.student_time?(toAdd.student_type=='AM'?true:false):true">AM</option>
                    <option value="PM" :selected="(toAdd.student_time&&toAdd.student_type=='PM')?true:false">PM</option>
                  </select>
                  <div v-if="errors.student_type" style="color:red;font-size:10px;">{{errors.student_type}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3 pb-0 pt-0" v-for="(day,index) in days" :key="index">
                  <v-checkbox :value="day.value" v-model="toAdd.selected_days" color="red" :label="day.text" :checked="toAdd.selected_days.includes(day.value)"></v-checkbox>
                </div>
                <div v-if="errors.selected_days" style="color:red;font-size:10px;">{{errors.selected_days}}</div>
              </div>
            </div>
            <div class="col-md-12" style="text-align:right;">
              <a class="btn btn-outline-pink btn-xs" v-if="!success" v-on:click.prevent="saveTimeSchedule()">SAVE</a>
              <p class="text-success" v-if="success">Time saved.</p>
            </div>
          </div>
        </div>
      </b-modal>
      <div class="alert error-message" :class="response=='success'?'alert-success':'alert-danger'" v-if="response">{{res_msg}}</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "chat-setting",
  data(){
    return {
      group:{
        tutor_id:null,
      },
      member:[],
      tutors:[],
      groups:[],
      members:[],
      loading:false,
      error:[],
      tutor_time:[],
      student_time:[],
      tutor_type:[],
      student_type:[],
      record:{
        id:null,
        type:null,
        index:null,
        group_id:null,
        group_index:null
      },
      days:[
        {text:'Sun',value:"Sunday"},
        {text:'Mon',value:"Monday"},
        {text:'Tue',value:"Tuesday"},
        {text:'Wed',value:"Wednesday"},
        {text:'Thu',value:"Thursday"},
        {text:'Fri',value:"Friday"},
        {text:'Sat',value:"Saturday"},
      ],
      toAdd:{
        selected_days:[],
        tutor_time:null,
        student_time:null,
        tutor_type:null,
        student_type:null,
        group_id:null,
        member_id:null
      },
      selected_days:[],
      response:null,
      res_msg:'',
      success: false,
      errors:{
        tutor_time:null,
        student_time:null,
        tutor_type:null,
        student_type:null,
        selected_days:null
      }
    }
  },
  beforeMount(){
    this.loading = true;
    this.getTutors();
    this.getGroups();
    this.getMembers();
  },
  methods:{
    getTutors(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/listTutors',{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.data.length){
          res.data.data.forEach(tutor => {
            if(tutor.users){
              this.tutors.push({value:tutor.users.user_id,text:tutor.name});
            }
          })
        }else{  
          this.tutors = [];
        }
      });
    },
    getMembers(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/students',{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.data.length){
          res.data.data.forEach(student => {
            if(student.user){
              this.members.push({value:student.user.user_id,text:student.name});
            }
          })
        }else{  
          this.members = [];
        }
      });
    },
    openTimeModal(group,member){
      this.errors = {
        tutor_time:null,
        student_time:null,
        tutor_type:null,
        student_type:null,
        selected_days:null
      }
      this.toAdd.group_id = group.id;
      this.toAdd.member_id = member.id;

      this.toAdd.tutor_time = member.tutor_time?member.tutor_time.split(' ')[0]:null;
      this.toAdd.student_time = member.student_time?member.student_time.split(' ')[0]:null;
      this.toAdd.tutor_type = member.tutor_time?member.tutor_time.split(' ')[1]:null;
      this.toAdd.student_type = member.tutor_time?member.tutor_time.split(' ')[1]:null;
      this.toAdd.selected_days = member.booked_days?JSON.parse(member.booked_days):[];
      this.$refs['confirm-time-modal'].show();
    },
    saveTimeSchedule(){
      var valid = 0;
      if(!this.toAdd.tutor_time){
        valid = valid + 1;
        this.errors.tutor_time ='Required'
      }
      if(!this.toAdd.student_time){
        valid = valid + 1;
        this.errors.student_time ='Required'
      }
      if(!this.toAdd.tutor_type){
        valid = valid + 1;
        this.errors.tutor_type ='Required'
      }
      if(!this.toAdd.student_type){
        valid = valid + 1;
        this.errors.student_type ='Required'
      }
      if(!this.toAdd.selected_days.length){
        valid = valid + 1;
        this.errors.selected_days ='Select atleast one day.'
      }
      if(valid == 0){
        this.errors = {
        tutor_time:null,
        student_time:null,
        tutor_type:null,
        student_type:null,
        selected_days:null
      }
        this.toAdd.tutor_class_time = this.toAdd.tutor_time+' '+this.toAdd.tutor_type;
        this.toAdd.student_class_time = this.toAdd.student_time+' '+this.toAdd.student_type;
        axios.put(process.env.VUE_APP_TUTION_SITE_API+'/add/time/'+this.toAdd.member_id,this.toAdd,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
          if(res.data.status == true){
            this.success = true;
            setTimeout(() => {
              this.$refs['confirm-time-modal'].hide();
            },3000)
          }
        });
      }
    },
    getGroups(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/list_groups',{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        this.loading = false;
        if(res.data.groups.length){
          res.data.groups.forEach(group => {
            this.groups.push(group);
          })
        }
      });
    },
    addGroup(){
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/add/group',this.group,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.status){
          res.data.group.members = [];
          this.groups.unshift(res.data.group)
        }else{
          console.log('error');
        }
      })
    },
    addMember(group_id,member_id,index){
      var request = {group_id:group_id,member_id:member_id};
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/add/member',request,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.status){
          this.groups[index].members.push(res.data.member);
        }else{
          if(res.data.error == 'member already exist'){
            this.error[index] = 'member already exist';
          }
        }
      });
    },
    confirmDelete(id,index){
      this.record.id = id,
      this.record.index = index,
      this.record.type = 'Group',
      this.$refs['confirm-modal'].show()
    },
    confirmDeleteMember(id,index,group_id,group_index){
      this.record.id = id,
      this.record.index = index,
      this.record.type = 'Member',
      this.record.group_id = group_id,
      this.record.group_index = group_index
      this.$refs['confirm-modal'].show()
    },
    deleteTutor(){
      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/delete/group/'+this.record.id,{},{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.message == 'group deleted successfully'){
          this.groups.splice(this.record.index,1);
          this.$refs['confirm-modal'].hide()
        }
      })
    },
    deleteMember(){
      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/remove/member/'+this.record.id,{group_id:this.record.group_id},{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.message == 'member deleted successfully'){
          this.groups[this.record.group_index].members.splice(this.record.index,1);
          this.$refs['confirm-modal'].hide()
        }
      })
    },
    resetTime(id,i,group_id,index){
      console.log(group_id);
      var req={
        group_id:group_id,
      }
      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/reset/time/'+id,req,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.status){
          this.response = 'success';
          this.res_msg = 'Time reseted';
          this.groups[index].members[i].tutor_time = null;
          this.groups[index].members[i].student_time = null;
          setTimeout(() => {
            this.response = null;
          },2000);
        }
      })
    },
    setTime(type,group_id,id){
      var req;
      if(type == 'T'){
        req = {
          group_id:group_id,
          time:document.getElementById('tutor_time'+id).value+' '+document.getElementById('tutorTimeType'+id).value,
          section:type,
        }
      }else{
        req = {
          group_id:group_id,
          time:document.getElementById('student_time'+id).value+' '+document.getElementById('studentTimeType'+id).value,
          section:type,
        }
      }

      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/add/time/'+id,req,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.status){
          this.response = 'success';
          this.res_msg = 'Time updated';
          this.groups.forEach(group =>{
            if(group.id == group_id){
              group.members.forEach(member =>{
                if(member.id == id){
                  if(type == 'T'){
                    member.tutor_time = req.time;
                  }else{
                     member.student_time = req.time;
                  }
                }
              })
            }
          })
          setTimeout(() => {
            this.response = null;
          },2000);
        }else{
          this.response = 'error';
          this.res_msg = 'Error!';
          setTimeout(() => {
            this.response = null;
          },2000);
        }
      });
    },
    setDays(day,index,i,member){
      console.log('here');
      console.log(day,index,i,member);
    }
  }
}
</script>

<style scoped>
.groups{
  background-color:white;
  margin-bottom:50px;
}
.tutor{
  border: 1px solid #ababab;
    padding: 20px;
}
.error-message{
  position: fixed !important;
  top: 60px;
  right: 20px;
  width: 180px;
  text-align: center;
}
</style>